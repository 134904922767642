import React from 'react'
import "./HomeOkr.scss"
import { jwtDecode } from "jwt-decode"
import ListaConsultorasOkr from "../../components/Okr/ListaConsultorasOkr"
import ListaAreasOkr from "../../components/Okr/ListaAreasOkr"
import ListaEmpresasOkr from '../../components/Okr/ListaEmpresasOkr'

function HomeOkr() {
    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const title = (
        <>
            <div className='section__header d-flex flex-row align-items-end mb-4'>
                <i className='bi bi-list-check me-2'></i>
                <h4 className='m-0'>OKR</h4>
            </div>
        </>
    )

    if(USER.puesto === 1) {
        return (
            <div className='home__okr section'>
                {title}
                <ListaConsultorasOkr user={USER}/>
            </div>
        )
    }

    if(USER.puesto === 2) {
        return (
            <div className='home__okr section'>
                {title}
                <ListaEmpresasOkr user={USER}/>
            </div>
        )
    }

    if(USER.puesto === 3 || USER.puesto === 4 || USER.puesto === 5) {
        return (
            <div className='home__okr section'>
                {title}
                <ListaAreasOkr user={USER}/>
            </div>
        )
    }
}

export default HomeOkr