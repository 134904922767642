import React from 'react'
import "./Home.scss"
import IllustrationBienvenida from "../../assets/img/ilustration-bienvenida.png"
import { jwtDecode } from "jwt-decode"
import ListaConsultoras from '../../components/Home/ListaConsultoras'
import ListaEmpresas from '../../components/Home/ListaEmpresas'
import ListaAreas from '../../components/Home/ListaAreas'

function Home() {
  const auth = localStorage.getItem("token")
  const jwtParse = jwtDecode(auth)
  const USER = jwtParse.apirest.objeto
  console.log(USER)

  const mensajeBienvenida = (
    <>
      <div className='section__header d-flex flex-row align-items-end mb-4'>
        <i className='bi bi-house-door-fill me-2'></i>
        <h4 className='m-0'>Dashboard</h4>
      </div>
      <div className='home__bienvenida d-flex flex-column flex-md-row rounded-3 align-items-md-center mb-4'>
        <div className='home__bienvenida__texto'>
          <h2 className='text-white m-0'><span>Bienvenido</span><br />{USER.nombre}</h2>
          {/* <p className='text-white m-0'>Tienes <b>{tareas}</b> tareas por realizar</p> */}
        </div>
        <div className='home__bienvenida__img d-flex align-self-center'>
          <img className="position-relative" src={IllustrationBienvenida} alt="" />
        </div>
      </div>
    </>
  )

  if(USER.puesto === 1) {
    return (
      <div className='home section'>
        {mensajeBienvenida}
        <ListaConsultoras />
      </div>
    )
  }

  if(USER.puesto === 2) {
    return (
      <div className='home section'>
        {mensajeBienvenida}
        <ListaEmpresas />
      </div>
    )
  }

  if(USER.puesto === 3 || USER.puesto === 4 || USER.puesto === 5) {
    return (
      <div className='home section'>
        {mensajeBienvenida}
        <ListaAreas />
      </div>
    )
  }
}

export default Home