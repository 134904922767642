import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import "./ModalNewOkr.scss"

import { detallePersContext } from '../../pages/Okr/DetalleOkrPersonal';
import { OkrContext } from '../../context/OkrContext';

function ModalEditOkrPersonal(props) {
    const { idOkr } = useParams()

    const { setOkrSelected, setLoading, okrPersObj, setOkrPersObj, USER } = useContext(detallePersContext)
    const { fetchOkrPersonales } = useContext(OkrContext)

    const [formData, setFormData] = useState({
        titulo: "",
        descripcion: "",
        fechaInicio: "",
        responsable: "",
        proyeccion: "",
        prioridad: "",
    })
    const [errors, setErrors] = useState({})
    const [modalErr, setModalErr] = useState(null)

    useEffect(() => {
        if(okrPersObj){
            const obj = JSON.parse(okrPersObj)
            setFormData({
                titulo: obj.titulo,
                descripcion: obj.descripcion,
                fechaInicio: obj.fecha_de_inicio,
                responsable: obj.mailUser,
                proyeccion: obj.proyeccion.toString(),
                prioridad: obj.prioridad.toString(),
            })
        }
    },[okrPersObj])

    const validateForm = (data) => {
        const errors = {}
    
        if(!data.titulo.trim()) {
            errors.titulo = "Escoja un título."
        }
        if(!data.descripcion.trim()) {
            errors.descripcion = "Escoja una descripción."
        }
        if(!data.fechaInicio.trim()) {
            errors.fechaInicio = "Escoja una fecha de inicio."
        }
        if(!data.responsable.trim()) {
            errors.responsable = "Escriba el mail."
        } else if (!/\S+@\S+\.\S+/.test(data.responsable)){
            errors.responsable = "El email no es válido."
        }
        if(!data.proyeccion.trim()) {
            errors.proyeccion = "Marca una opción."
        }
        if(!data.prioridad.trim()) {
            errors.prioridad = "Marca una opción."
        }
        return errors;
    }

    const handleClose = () => {
        setErrors({})
        setFormData({
            titulo: "",
            descripcion: "",
            fechaInicio: "",
            responsable: "",
            proyeccion: "",
            prioridad: "",
        })
        props.onHide()
        setModalErr(null)
        setOkrPersObj(null)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleChangeOkr = async (e) => {
            e.preventDefault()
            const newErrors = validateForm(formData);
            setErrors(newErrors)
            const objSelec = JSON.parse(okrPersObj)
            if (Object.keys(newErrors).length === 0) {
                const obj = {
                    idObjetivo: parseInt(idOkr),
                    user: USER,
                    titulo: formData.titulo,
                    descripcion: formData.descripcion,
                    responsable: objSelec.mailUser,
                    proyeccion: parseInt(formData.proyeccion),
                    prioridad: parseInt(formData.prioridad),
                    fechaInicio: formData.fechaInicio
                }
                try {
                    const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/modObjetivo`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(obj)
                    })
                    const data = await res.json()
                    if(data.status !== 0){
                        setModalErr(data.codeError)
                    } else {
                        setLoading(true)
                        setModalErr(null)
                        setOkrPersObj(null)
                        props.onHide()
                        // BUSCAR EL OKR ACTUALIZADO POR ID
                        fetchOkrPersonales(USER)
                        .then(res => {
                            if(res.error !== 0){
                                console.log(res.errorDetalle)
                            } else {
                                const obj = (res.objeto).find((e) => e.id_objetivo === parseInt(idOkr))
                                setOkrSelected((prevState) => ({
                                    ...prevState,
                                    titulo: obj.titulo,
                                    descripcion: obj.descripcion,
                                    fecha_de_inicio: obj.fecha_de_inicio,
                                    proyeccion: parseInt(obj.proyeccion),
                                    prioridad: parseInt(obj.prioridad),
                                    nombreUser: obj.nombreUser,
                                    mailUser: obj.mailUser,
                                    fk_responsable: obj.fk_responsable,
                                    progresoGeneral: Math.round(obj.progresoGeneral)
                                }))
                            }
                        })
                        setLoading(false)
                    }
                } catch (error) {
                    setModalErr(error)
                }
            } else {
                setModalErr("Completar los campos mencionados.")
            }
        }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='modal__okr'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='d-flex flex-row'>
                    <h3 className='m-0'>Modificar Okr</h3>
                    <button className='btn' onClick={handleClose}><i className="bi bi-x-lg fw-bold"></i></button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='form__okr d-flex flex-column' onSubmit={handleChangeOkr}>
                    {/* Título */}
                    <div className='mb-2'>
                        <label className='mb-1'>Título</label>
                        <input
                            onChange={handleChange}
                            type="text" 
                            id="titulo" 
                            name="titulo" 
                            autoFocus
                            className="form-control form-control-sm col-12"
                            value={formData.titulo}
                        />
                        {errors.titulo && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.titulo}</span>}
                    </div>
                    {/* Descripción */}
                    <div className='mb-2'>
                        <label className='mb-1'>Descripción</label>
                        <input
                            onChange={handleChange}
                            type="text" 
                            id="descripcion" 
                            name="descripcion" 
                            className="form-control form-control-sm col-12"
                            value={formData.descripcion}
                        />
                        {errors.descripcion && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.descripcion}</span>}
                    </div>
                    {/* Proyección y fecha de inicio */}
                    <div className='row mb-2'>
                        <div className="col-12 col-md-6">
                            <label className='mb-1'>Proyección</label>
                            <select className="form-select form-select-sm" id="proyeccion" name="proyeccion" onChange={handleChange} value={formData.proyeccion}>
                                <option value="">Elija una opción</option>
                                <option value="1">Quincenal</option>
                                <option value="2">Mensual</option>
                                <option value="3">Bimestral</option>
                                <option value="4">Trimestral</option>
                                <option value="5">Semestral</option>
                                <option value="6">Anual</option>
                            </select>
                            {errors.proyeccion && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.proyeccion}</span>}
                        </div>
                        <div className='col-12 col-md-6'>
                            <label className='mb-1'>Fecha de inicio</label>
                            <input
                                onChange={handleChange}
                                type="date" 
                                id="fechaInicio" 
                                name="fechaInicio" 
                                className="form-control form-control-sm"
                                value={formData.fechaInicio}
                            />
                            {errors.fechaInicio && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fechaInicio}</span>}
                        </div>
                    </div>
                    {/* Responsable */}
                    <div className='mb-2'>
                        <label className='mb-1'>Correo del responsable</label>
                        <input
                            onChange={handleChange}
                            type="email" 
                            id="responsable" 
                            name="responsable" 
                            placeholder="usuario@correo.com.ar" 
                            className="form-control form-control-sm col-12"
                            value={formData.responsable}
                            disabled={USER.puesto === 5 ? true : false}
                        />
                        {errors.responsable && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.responsable}</span>}
                    </div>
                    {/* Prioridad */}
                    <div className='row mb-2'>
                        <div className='col-12 col-md-6'>
                            <label className='mb-1'>Prioridad</label>
                            <div className='d-flex flex-row'>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="1"
                                        checked={formData.prioridad === "1"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Baja</label>
                                </div>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="2" 
                                        checked={formData.prioridad === "2"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">Media</label>
                                </div>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="3" 
                                        checked={formData.prioridad === "3"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">Alta</label>
                                </div>
                            </div>
                            {errors.prioridad && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.prioridad}</span>}
                        </div>
                    </div>
                    {modalErr !== null && <span className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{modalErr}</span>}
                    <button type="submit" className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-center'>
                        Modificar Okr
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEditOkrPersonal