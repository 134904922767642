import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { ProgressBar } from 'react-bootstrap';
import Avatar from "../assets/img/avatar-3.jpg"
import "./Carrusel.scss"

const CarruselOkrByEmpresa = ({ items, user }) => {

    const { idConsult, id } = useParams()

    // const ruta = user.puesto >= 3 ? "" : `/consultora/${idConsult}/empresa/${id}`
    let ruta;
    if(user.puesto === 1){
        ruta = `/consultora/${idConsult}/empresa/${id}`
    }
    if(user.puesto === 2){
        ruta = `/empresa/${id}`
    }
    if(user.puesto >= 3){
        ruta = ""
    }

    return (
        <>
            {items.length > 4 ? (
                <div className='swiper-container items__container--carrusel'>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                    <Swiper 
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={16} 
                        slidesPerView={4}
                        navigation={{
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next",
                        }}
                        pagination={{ el: ".swiper-pagination-persnl", clickable: true }}
                    >
                        {items.map((e,i) => {
                            return <SwiperSlide key={i} className="item__container item--carrusel">
                                <Link to={`/okr${ruta}/empresariales/${e.id_objetivo}`} className='btn each__item p-3 d-flex flex-column align-items-start shadow-sm rounded-3 border border-light-subtle'>
                                    <p className='title__xs mb-2'>OKR</p>
                                    <h4 className='title text-start mb-2'>{e.titulo}</h4>
                                    <div className='mb-2 d-flex flex-row align-items-center'>
                                        <p className='m-0 me-1'>0</p>
                                        <span className='pill__kr rounded-pill text-white'>KR</span>
                                    </div>
                                    <div className='avatar__container mb-2 d-flex flex-row align-items-center'>
                                        <img className='avatar me-2 rounded-circle' src={Avatar} alt='avatar'/>
                                        <p className='m-0 p-0'>{e.nombreUser}</p>
                                    </div>
                                    <ProgressBar className='progreso__bar' now={Math.round(e.progresoGeneral)} label={`${Math.round(e.progresoGeneral)}%`} max={100}/>
                                </Link>
                            </SwiperSlide>
                        })}
                    </Swiper>
                    <div className="swiper-pagination-persnl"></div>
                </div>
            ) : (
                <div className='items__container mb-4'>
                    {items.map((e,i) => {
                        return <div key={i} className='item__container'>
                            <Link to={`/okr${ruta}/empresariales/${e.id_objetivo}`} className='btn each__item p-3 d-flex flex-column align-items-start shadow-sm rounded-3 border border-light-subtle'>
                                <p className='title__xs mb-2'>OKR</p>
                                <h4 className='title mb-2'>{e.titulo}</h4>
                                <div className='mb-2 d-flex flex-row align-items-center'>
                                    <p className='m-0 me-1'>0</p>
                                    <span className='pill__kr rounded-pill text-white'>KR</span>
                                </div>
                                <div className='avatar__container mb-2 d-flex flex-row align-items-center'>
                                    <img className='avatar me-2 rounded-circle' src={Avatar} alt='avatar'/>
                                    <p className='m-0 p-0'>{e.nombreUser}</p>
                                </div>
                                <ProgressBar className='progreso__bar' now={Math.round(e.progresoGeneral)} label={`${Math.round(e.progresoGeneral)}%`} max={100}/>
                            </Link>
                        </div>
                    })}
                </div>
            )}
        </>
    )
}

export default CarruselOkrByEmpresa