import React, { useState, useEffect, useContext } from 'react'
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import "./ModalNewOkr.scss"
import { jwtDecode } from "jwt-decode"

import { OkrContext } from '../../context/OkrContext';
import { areasOkrContext } from '../../pages/Okr/AreasOkr';

function ModalNewOkrPro(props) {
    const { id } = useParams()
    
    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const { fetchOkrByEmpresa } = useContext(OkrContext)
    const { setLoadingOkr, setErrorOkr, setOkrsEmpresa } = useContext(areasOkrContext)
    
    const [formData, setFormData] = useState({
        titulo: "",
        descripcion: "",
        fechaInicio: "",
        responsable: "",
        proyeccion: "",
        prioridad: "",
    })
    const [errors, setErrors] = useState({})
    const [modalErr, setModalErr] = useState(null)

    useEffect(() => {
    }, [])

    const validateForm = (data) => {
        const errors = {}
    
        if(!data.titulo.trim()) {
            errors.titulo = "Escoja un título."
        }
        if(!data.descripcion.trim()) {
            errors.descripcion = "Escoja una descripción."
        }
        if(!data.fechaInicio.trim()) {
            errors.fechaInicio = "Escoja una fecha de inicio."
        }
        if(!data.responsable.trim()) {
            errors.responsable = "Escriba el mail."
        } else if (!/\S+@\S+\.\S+/.test(data.responsable)){
            errors.responsable = "El email no es válido."
        }
        if(!data.proyeccion.trim()) {
            errors.proyeccion = "Marca una opción."
        }
        if(!data.prioridad.trim()) {
            errors.prioridad = "Marca una opción."
        }
        return errors;
    }

    const handleClose = () => {
        setErrors({})
        setFormData({
            titulo: "",
            descripcion: "",
            fechaInicio: "",
            responsable: "",
            proyeccion: "",
            prioridad: "",
        })
        props.onHide()
        setModalErr(null)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validateForm(formData);
        setErrors(newErrors)
        if (Object.keys(newErrors).length === 0) {
            const obj = {
                user: USER,
                titulo: formData.titulo,
                descripcion: formData.descripcion,
                responsable: formData.responsable,
                proyeccion: parseInt(formData.proyeccion),
                prioridad: parseInt(formData.prioridad),
                fechaInicio: formData.fechaInicio
            }
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newObjetivo`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                const data = await res.json()
                if(data.status !== 0){
                    setModalErr(data.errorDetalle)
                } else {
                    setFormData({
                        titulo: "",
                        descripcion: "",
                        fechaInicio: "",
                        responsable: "",
                        proyeccion: "",
                        prioridad: "",
                    })
                    setModalErr(null)
                    props.onHide()
                    // actualiza okrs
                    fetchOkrByEmpresa(USER, id)
                    .then(res => {
                        if(res.error !== 0){
                            setLoadingOkr(false)
                            setErrorOkr(res.errorDetalle)
                        } else {
                            setLoadingOkr(false)
                            setOkrsEmpresa(res.objeto)
                        }
                    })
                }
            } catch (error) {
                setModalErr(error)
            }
        } else {
            setModalErr("Completar los campos mencionados.")
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='modal__okr'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='d-flex flex-row'>
                    <h3 className='m-0'>Agregar Okr</h3>
                    <button className='btn' onClick={handleClose}><i className="bi bi-x-lg fw-bold"></i></button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='form__okr d-flex flex-column' onSubmit={handleSubmit}>
                    {/* Título */}
                    <div className='mb-2'>
                        <label className='mb-1'>Título</label>
                        <input
                            onChange={handleChange}
                            type="text" 
                            id="titulo" 
                            name="titulo" 
                            autoFocus
                            className="form-control form-control-sm col-12"
                            value={formData.titulo}
                        />
                        {errors.titulo && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.titulo}</span>}
                    </div>
                    {/* Descripción */}
                    <div className='mb-2'>
                        <label className='mb-1'>Descripción</label>
                        <input
                            onChange={handleChange}
                            type="text" 
                            id="descripcion" 
                            name="descripcion" 
                            className="form-control form-control-sm col-12"
                            value={formData.descripcion}
                        />
                        {errors.descripcion && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.descripcion}</span>}
                    </div>
                    {/* Proyección y fecha de inicio */}
                    <div className='row mb-2'>
                        <div className="col-12 col-md-6">
                            <label className='mb-1'>Proyección</label>
                            <select className="form-select form-select-sm" id="proyeccion" name="proyeccion" onChange={handleChange} value={formData.proyeccion}>
                                <option value="">Elija una opción</option>
                                <option value="1">Quincenal</option>
                                <option value="2">Mensual</option>
                                <option value="3">Bimestral</option>
                                <option value="4">Trimestral</option>
                                <option value="5">Semestral</option>
                                <option value="6">Anual</option>
                            </select>
                            {errors.proyeccion && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.proyeccion}</span>}
                        </div>
                        <div className='col-12 col-md-6'>
                            <label className='mb-1'>Fecha de inicio</label>
                            <input
                                onChange={handleChange}
                                type="date" 
                                id="fechaInicio" 
                                name="fechaInicio" 
                                className="form-control form-control-sm"
                                value={formData.fechaInicio}
                            />
                            {errors.fechaInicio && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fechaInicio}</span>}
                        </div>
                    </div>
                    {/* Responsable */}
                    <div className='mb-2'>
                        <label className='mb-1'>Correo del responsable</label>
                        <input
                            onChange={handleChange}
                            type="email" 
                            id="responsable" 
                            name="responsable" 
                            placeholder="usuario@correo.com.ar" 
                            className="form-control form-control-sm col-12"
                            value={formData.responsable}
                        />
                        {errors.responsable && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.responsable}</span>}
                    </div>
                    {/* Prioridad */}
                    <div className='row mb-2'>
                        <div className='col-12 col-md-6'>
                            <label className='mb-1'>Prioridad</label>
                            <div className='d-flex flex-row'>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="1"
                                        checked={formData.prioridad === "1"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Baja</label>
                                </div>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="2" 
                                        checked={formData.prioridad === "2"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">Media</label>
                                </div>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="3" 
                                        checked={formData.prioridad === "3"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">Alta</label>
                                </div>
                            </div>
                            {errors.prioridad && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.prioridad}</span>}
                        </div>
                    </div>
                    {modalErr !== null && <span className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{modalErr}</span>}
                    <button type="submit" className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-center'>
                        Agregar Okr
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalNewOkrPro