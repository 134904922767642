import React, { useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import "./ModalNewOkr.scss"

import { detalleContext } from '../../pages/Okr/DetalleOkr'

function ModalNewKeyResult(props) {
    const { idOkr } = useParams()

    const { setLoadingKeyResults, fetchKRByObjetivo, USER } = useContext(detalleContext)

    const [formData, setFormData] = useState({
        titulo: "",
        responsable: "",
        prioridad: "",
        tipo: "1",
        base: "",
        resultado: "",
        medicion: "",
        fecha: ""
    })

    const [selectedGroup, setSelectedGroup] = useState("1")

    const [errors, setErrors] = useState({})
    const [modalErr, setModalErr] = useState(null)

    const [listaHitos, setListaHitos] = useState([])
    const [hitoNuevo, setHitoNuevo] = useState("")
    const [errorHito, setErrorHito] = useState(null)

    const validateForm = (data) => {
        const errors = {}
    
        if(!data.titulo.trim()) {
            errors.titulo = "Escoja un título."
        }
        if(!data.responsable.trim()) {
            errors.responsable = "Escriba el mail."
        } else if (!/\S+@\S+\.\S+/.test(data.responsable)){
            errors.responsable = "El email no es válido."
        }
        if(!data.prioridad.trim()) {
            errors.prioridad = "Marca una opción."
        }
        if(!data.fecha.trim()){
            errors.fecha = "Escoja una fecha."
        }
        // DATO TIPO 1
        if(data.tipo === "1"){
            if(!data.base.trim()){
                errors.base = "Escoge una línea base."
            }
            if(!data.resultado.trim()){
                errors.resultado = "Escoge un resultado esperado."
            }
            if(!data.medicion.trim()){
                errors.medicion = "Escoge una de las opciones."
            }
        }
        // DATO TIPO 2
        if(data.tipo === "2"){
            if(listaHitos.length === 0){
                errors.hito = "Ingresa un valor."
            }
        }
        return errors;
    }

    const handleClose = () => {
        setErrors({})
        setFormData({
            titulo: "",
            responsable: "",
            prioridad: "",
            tipo: "1",
            base: "",
            resultado: "",
            medicion: "",
            fecha: ""
        })
        props.onHide()
        setModalErr(null)
        setSelectedGroup("1")
        setErrorHito(null)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const newErrors = validateForm(formData)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            const obj = {
                idObjetivo: idOkr,
                user: USER,
                responsable: formData.responsable,
                tipo: parseInt(formData.tipo),
                prioridad: parseInt(formData.prioridad),
                frecuencia: parseInt(formData.medicion),
                lineaBase: parseInt(formData.base),
                resultadoEsperado: parseInt(formData.resultado),
                titulo: formData.titulo,
                fecha: formData.fecha
            }
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newKeyResults`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                const data = await res.json()
                if(data.status !== 0){
                    setModalErr(data.codeError)
                } else {
                    if(data.objeto.tipo === 1) {
                        const objIngreso = {
                            krId: data.objeto.id_kr,
                            user: USER,
                            dato: parseInt(formData.base),
                            fecha: formData.fecha
                        }
                        const addIngreso = async () => {
                            try {
                                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newActividades`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(objIngreso)
                                })
                                const data = await res.json()
                                if(data.status !== 0){
                                    setModalErr("No se pudo agregar el nuevo ingreso.")
                                } else {
                                    setFormData({
                                        titulo: "",
                                        responsable: "",
                                        prioridad: "",
                                        tipo: "1",
                                        base: "",
                                        resultado: "",
                                        medicion: "",
                                        fecha: ""
                                    })
                                    setModalErr(null)
                                    props.onHide()
                                    // Actualizar listado de Key Results
                                    setLoadingKeyResults(true)
                                    fetchKRByObjetivo()
                                }
                            } catch (error) {
                                setModalErr(error)
                            }
                        }
                        addIngreso()
                    }
                    if(data.objeto.tipo === 2) {
                        listaHitos.forEach((e) => {
                            const objActividad = {
                                krId: data.objeto.id_kr,
                                user: USER,
                                dato: 0,
                                detalles: e,
                                fecha: formData.fecha
                            }
                            const addHito = async () => {
                                try {
                                    const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newActividades`, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body: JSON.stringify(objActividad)
                                    })
                                    const data = await res.json()
                                    if(data.status !== 0){
                                        setModalErr("No se pudo agregar los hitos.")
                                    } else {
                                        setFormData({
                                            titulo: "",
                                            responsable: "",
                                            prioridad: "",
                                            tipo: "1",
                                            base: "",
                                            resultado: "",
                                            medicion: "",
                                            fecha: ""
                                        })
                                        setModalErr(null)
                                        props.onHide()
                                        // Actualizar listado de Key Results
                                        setLoadingKeyResults(true)
                                        fetchKRByObjetivo()
                                    }
                                } catch (error) {
                                    setModalErr(error)
                                }
                            }
                            addHito()
                        })
                    }
                    setFormData({
                        titulo: "",
                        responsable: "",
                        prioridad: "",
                        tipo: "1",
                        base: "",
                        resultado: "",
                        medicion: "",
                        fecha: ""
                    })
                    setModalErr(null)
                    props.onHide()
                    // Actualizar listado de Key Results
                    setLoadingKeyResults(true)
                    fetchKRByObjetivo()
                }
            } catch (error) {
                setModalErr(error)
            }
        } else {
            setModalErr("Completar los campos mencionados.")
        }
    }

    const handleGroupChange = (e) => {
        setSelectedGroup(e.target.value)
        setModalErr(null)
        if(e.target.value === "1") {
            setErrors({})
            setFormData((prevState) => ({
                ...prevState,
                tipo: "1"
            }))
            setListaHitos([])
            setErrorHito(null)
        } else if (e.target.value === "2") {
            setErrors({})
            setFormData((prevState) => ({
                ...prevState,
                base: "",
                resultado: "",
                medicion: "",
                tipo: "2"
            }))
            setErrorHito(null)
        }
    }

    const handleAddHito = (e) => {
        e.preventDefault()
        if (hitoNuevo.trim() !== "") {
            setListaHitos((prevLista) => [...prevLista, hitoNuevo])
            setHitoNuevo("")
            setErrorHito(null)
        } else {
            setErrorHito("Ingresa un valor.")
        }
    }

    const handleDeleteHito = (index) => {
        setListaHitos((prevLista) => prevLista.filter((_, i) => i !== index));
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='modal__okr'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='d-flex flex-row'>
                    <h3 className='m-0'>Agregar Key Result</h3>
                    <button className='btn' onClick={handleClose}><i className="bi bi-x-lg fw-bold"></i></button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='form__okr d-flex flex-column' onSubmit={handleSubmit}>
                    {/* Título y fecha */}
                    <div className='row mb-2'>
                        <div className='col-12 col-md-7'>
                            <label className='mb-1'>Título</label>
                            <input
                                onChange={handleChange}
                                type="text" 
                                id="titulo" 
                                name="titulo" 
                                autoFocus
                                className="form-control form-control-sm col-12"
                                value={formData.titulo}
                            />
                            {errors.titulo && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.titulo}</span>}
                        </div>
                        <div className='col-12 col-md-5'>
                            <label className='mb-1'>Fecha</label>
                            <input
                                onChange={handleChange}
                                type="date" 
                                id="fecha" 
                                name="fecha" 
                                className="form-control form-control-sm"
                                value={formData.fecha}
                            />
                            {errors.fecha && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fecha}</span>}
                        </div>
                    </div>
                    {/* Responsable y prioridad */}
                    <div className='row mb-4'>
                        <div className='col-12 col-md-7'>
                            <label className='mb-1'>Correo del responsable</label>
                            <input
                                onChange={handleChange}
                                type="email" 
                                id="responsable" 
                                name="responsable" 
                                placeholder="usuario@correo.com.ar" 
                                className="form-control form-control-sm col-12"
                                value={formData.responsable}
                            />
                            {errors.responsable && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.responsable}</span>}
                        </div>
                        <div className='col-12 col-md-5'>
                            <label className='mb-1'>Prioridad</label>
                            <div className='d-flex flex-row'>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="1"
                                        checked={formData.prioridad === "1"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Baja</label>
                                </div>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="2" 
                                        checked={formData.prioridad === "2"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">Media</label>
                                </div>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="3" 
                                        checked={formData.prioridad === "3"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">Alta</label>
                                </div>
                            </div>
                            {errors.prioridad && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.prioridad}</span>}
                        </div>
                    </div>
                    <h4 className='text-blue-500'>Tipo de Key Result</h4>
                    {/* ESCOGER QUE TIPO DE KEY RESULT */}
                    <div className='row mb-2'>
                        <div className='col-12 col-md-6'>
                            <label className='mb-1'>Escoge una opción:</label>
                            <div className='d-flex flex-row'>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="tipo" 
                                        value="1"
                                        checked={selectedGroup === "1"}
                                        onChange={handleGroupChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Medible</label>
                                </div>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="tipo" 
                                        value="2" 
                                        checked={selectedGroup === "2"}
                                        onChange={handleGroupChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">No Medible</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedGroup === "1" && (
                        <>
                            {/* Línea base y resultado esperado */}
                            <div className='row mb-2'>
                                <div className='col-12 col-md-6'>
                                    <label className='mb-1'>Línea base</label>
                                    <input
                                        onChange={handleChange}
                                        type="number" 
                                        id="base" 
                                        name="base" 
                                        className="input--arrows form-control form-control-sm col-12"
                                        value={formData.base}
                                    />
                                    {errors.base && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.base}</span>}
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label className='mb-1'>Resultado esperado</label>
                                    <input
                                        onChange={handleChange}
                                        type="number" 
                                        id="resultado" 
                                        name="resultado" 
                                        className="input--arrows form-control form-control-sm col-12"
                                        value={formData.resultado}
                                    />
                                    {errors.resultado && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.resultado}</span>}
                                </div>
                                
                            </div>
                            {/* Frecuencia de medición */}
                            <div className='row mb-4'>
                                <div className='col-12 col-md-6'>
                                    <label className='mb-1'>Frecuencia de medición</label>
                                    <select className="form-select form-select-sm" id="medicion" name="medicion" onChange={handleChange} value={formData.medicion}>
                                        <option value="">Elija una opción</option>
                                        <option value="1">Mensual</option>
                                        <option value="2">Bimestral</option>
                                        <option value="3">Trimestral</option>
                                    </select>
                                    {errors.medicion && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.medicion}</span>}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedGroup === "2" && (
                        <div className='row mb-4'>
                            <div className='col-12 col-md-6 addHito m-0'>
                                <p className='m-0 mb-2'>Agregar hito</p>
                                <div className='row d-flex align-items-center'>
                                    <div className='col-10'>
                                        <input
                                            onChange={(e) => setHitoNuevo(e.target.value)}
                                            type="text" 
                                            id="hito" 
                                            name="hito" 
                                            placeholder='Ingresa el hito'
                                            className="form-control form-control-sm"
                                            value={hitoNuevo}
                                        />
                                    </div>
                                    <div className='col-2 p-0'>
                                        <button onClick={handleAddHito} className='addHito__btn d-flex align-items-center justify-content-center p-0 btn btn-primary rounded-circle'><i className="bi bi-plus-lg"></i></button>
                                    </div>
                                </div>  
                                {errors.hito && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.hito}</span>}
                                {errorHito !== null && <span className='text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{errorHito}</span>}
                            </div>
                            <ul className={`col-12 col-md-6 lista__hitos m-0 ${listaHitos.length >= 1 ? "p-3 border border-light-subtle rounded-3 scroll--y" : ""}`}>
                                {listaHitos.map((e,i) => {
                                    return <li key={i} className='my-1 d-flex flex-row'>
                                        <button onClick={()=> handleDeleteHito(i)} className='btn__delete__hito me-2 p-0 d-flex align-items-center justify-content-center btn btn-danger rounded-circle'><i className="bi bi-x"></i></button>
                                        <div className='texto__hito'>{e}</div>
                                    </li>
                                })}
                            </ul>
                        </div>
                    )}
                    {modalErr !== null && <span className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{modalErr}</span>}
                    <button type="submit" className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-center'>
                        Agregar Key Result
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalNewKeyResult