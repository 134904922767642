import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import "./DetalleOkr.scss"
import { jwtDecode } from "jwt-decode"
import Avatar from "../../assets/img/avatar-3.jpg"
import { Oval } from 'react-loader-spinner'
import { ProgressBar, Modal } from 'react-bootstrap';
import ModalEditOkrPersonal from '../../components/Modales/ModalEditOkrPersonal'
import ModalNewKeyResultPers from '../../components/Modales/ModalNewKeyResultPers'

import { OkrContext } from '../../context/OkrContext'

export const detallePersContext = React.createContext()

function DetalleOkrPersonal() {
    const { idOkr } = useParams()

    const navigate = useNavigate()

    const { fetchOkrPersonales, fetchComentarios } = useContext(OkrContext)

    // STATE
    const [okrSelected, setOkrSelected] = useState(null)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [okrPersObj, setOkrPersObj] = useState(null)

    const [loadingKeyResults, setLoadingKeyResults] = useState(true)
    const [errorKeyResults, setErrorKeyResults] = useState(null)
    const [krsByObjetivo, setKrsByObjetivo] = useState([])

    const [modalEditOkr, setModalEditOkr] = useState(false)

    const [modalDeleteOkr, setModalDeleteOkr] = useState(false)
    const [errorDel, setErrorDel] = useState(null)

    const [modalNewKeyResult, setModalNewKeyResult] = useState(false)

    const [comentarios, setComentarios] = useState([])
    const [loadingComentarios, setLoadingComentarios] = useState(true)
    const [errorComentarios, setErrorComentarios] = useState(null)
    const [comentarioNuevo, setComentarioNuevo] = useState("")
    const [msjErrorComentario, setMsjErrorComentario] = useState(null)

    // USER
    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    useEffect(() => {
        const firstFetch = async () => {
            //OKR PERSONALES
            fetchOkrPersonales(USER)
            .then(res => {
                if(res.error !== 0){
                    setLoading(false)
                    setError(res.errorDetalle)
                } else {
                    setLoading(false)
                    const obj = (res.objeto).find((e) => e.id_objetivo === parseInt(idOkr))
                    setOkrSelected(obj)
                }
            })

            fetchComentarios(USER, idOkr)
            .then(res => {
                if(res.status !== 0){
                    setLoadingComentarios(false)
                    setErrorComentarios(res.codeError)
                } else {
                    setLoadingComentarios(false)
                    setComentarios(res.objeto)
                }
            })
        }
        firstFetch()

        fetchKRByObjetivo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetchKRByObjetivo = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewKeyResultsObjetive`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    idObjetivo: idOkr
                })
            });
            const data = await res.json()
            if(data.status !== 0){
                setLoadingKeyResults(false)
                setErrorKeyResults(data.errorDetalle)
            } else {
                setLoadingKeyResults(false)
                setKrsByObjetivo(data.objeto)
            }
        } catch (error) {
            setErrorKeyResults(error)
        }
    }

    const handleEditOkr = (id) => {
        // const obj = totalArr.find((e) => e.id_objetivo === parseInt(id))
        setOkrPersObj(JSON.stringify(okrSelected))
        setModalEditOkr(true)
    }

    const handleModalDeleteOkr = () => {
        setModalDeleteOkr(true)
    }

    const handleDeleteOkr = async () => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/deleteObjetivo`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user: USER,
              idObjetivo: idOkr
            })
          });
          const data = await res.json()
          if(data.error !== 0){
            setErrorDel(data.codeError)
          } else {
            setModalDeleteOkr(false)
            navigate("/okr")
          }
        } catch (error) {
          setErrorDel(error)
        }
    }

    const handleCloseEliminar = () => {
        setModalDeleteOkr(false)
        setErrorDel(null)
    }

    const handleNewKeyResult = () => {
        setModalNewKeyResult(true)
    }

    const handleNewComment = async (e) => {
        e.preventDefault()
        if(comentarioNuevo.trim() !== ""){
            const obj = {
                idObjetivo: idOkr,
                user: USER,
                cuerpo: comentarioNuevo
            }
            setLoadingComentarios(true)
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newComentario`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                const data = await res.json()
                if(data.status !== 0){
                    setLoadingComentarios(false)
                    setMsjErrorComentario(data.errorDetalle)
                } else {
                    setLoadingComentarios(false)
                    setMsjErrorComentario(null)
                    setComentarioNuevo("")
                    fetchComentarios(USER, idOkr).then(res => setComentarios(res.objeto))
                }
            } catch (error) {
                setMsjErrorComentario(error)
            }
        } else {
            setMsjErrorComentario("Debes agregar un comentario.")
        }
    }
    
    return (
        <>
            <detallePersContext.Provider value={{setLoading, setOkrSelected, okrPersObj, setOkrPersObj, USER, setLoadingKeyResults, fetchKRByObjetivo}}>
                {/* Modal Eliminar OKR */}
                <Modal className='modal__delete' show={modalDeleteOkr} onHide={() => setModalDeleteOkr(false)} backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title className='d-flex flex-row'>
                            <h3>Eliminar Okr</h3>
                            <button className='btn' onClick={handleCloseEliminar}><i className="bi bi-x-lg fw-bold"></i></button>
                        </Modal.Title> 
                    </Modal.Header>
                    <Modal.Body>¿Está seguro de eliminar este Okr?</Modal.Body>
                    <Modal.Footer className='d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center align-self-end'>
                            <button className='btn btn-secondary rounded-pill me-2' onClick={handleCloseEliminar}>Cancelar</button>
                            <button className='btn btn-danger rounded-pill' onClick={handleDeleteOkr}>Borrar</button>
                        </div>
                        {errorDel && <p className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{errorDel}</p>}
                    </Modal.Footer>
                </Modal>
                {/* Modal Editar OKR */}
                <ModalEditOkrPersonal show={modalEditOkr} onHide={()=>setModalEditOkr(false)} />
                {/* Modal Crear Key Result */}
                <ModalNewKeyResultPers show={modalNewKeyResult} onHide={()=>setModalNewKeyResult(false)} />
                <div className='okrDetalle section'>
                    <div className='section__header d-flex flex-row align-items-end mb-4'>
                        <i className='bi bi-list-check me-2'></i>
                        <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> <i className="bi bi-chevron-right"></i> Detalle del OKR</h4>
                    </div>
                    <div className='okrDetalle__main'>
                        <section className='okrDetalle__main__info scroll--y'>
                        {loading ? (
                            <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                <Oval
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#0d6efd"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                                <p className='fw-medium'>Loading...</p>
                            </div>
                        ) : (
                        <>
                            {error ? (
                                <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                    <h3 className='text-danger mb-1'>Mensaje de error:</h3>
                                    <p className='m-0'>{error}</p>
                                </div>
                            ) : (
                            <>
                                {okrSelected && <>
                                    <div className='okrDetalle__main__info__title d-flex flex-row align-items-center mb-2'>
                                        <h3 className='m-0 me-3 text-blue-500'>{okrSelected.titulo}</h3>
                                        <div className='d-flex flex-column flex-md-row'>
                                            <button onClick={()=> handleEditOkr(idOkr)} className='btn__edit btn bg-success rounded-circle mb-2 mb-md-0 me-md-2 text-white'><i className="bi bi-pencil"></i></button>
                                            <button onClick={handleModalDeleteOkr} className='btn__delete btn bg-danger rounded-circle text-white'><i className="bi bi-trash3"></i></button>
                                        </div>
                                    </div>
                                    <p className='mb-4'>{okrSelected.descripcion}</p>
                                    <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(okrSelected.progresoGeneral)} label={`${Math.round(okrSelected.progresoGeneral)}%`} max={100}/>
                                </>
                                }
                            </>
                            )}
                        </>
                        )}
                        {/* KEY RESULTS BY OKR */}
                        <div className='mb-4'>
                            <div className='d-flex flex-row justify-content-between align-items-center mb-3'>
                                <h4 className='text-blue-500 m-0'>Key Results</h4>
                                <button onClick={handleNewKeyResult} className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium'>Agregar KR</button>
                            </div>
                            {loadingKeyResults ? (
                                <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                    <Oval
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#0d6efd"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                    <p className='fw-medium'>Loading...</p>
                                </div>
                            ) : (
                                <>
                                    {errorKeyResults ? (
                                        <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                            <h3 className='text-danger mb-1'>Mensaje de error:</h3>
                                            <p className='m-0'>{errorKeyResults}</p>
                                        </div>
                                    ) : (
                                        <>
                                            {krsByObjetivo.length === 0 ? (
                                                <div className='okr--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                                                    <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes Key Results asignados en este OKR.</h3>
                                                    <p className='mb-0 text-center'>Para comenzar, ingresa tu primer Key Result.</p>
                                                </div>
                                            ) : (
                                                <div className='table__custom__body'>
                                                    {krsByObjetivo.map((e, i) => {
                                                    return <Link key={i} to={`/okr/personales/${idOkr}/keyresult/${e.id_kr}`} className='text-decoration-none text-dark table__custom__row d-flex flex-row justify-content-between'>
                                                        <div className='d-flex flex-row align-items-center'>
                                                            <div className='table__custom__cell'>
                                                                <span className='pill__kr rounded-pill text-white'>KR</span>
                                                            </div>
                                                            <div className='table__custom__cell'>{e.titulo}</div>
                                                            </div>
                                                            <div className='d-flex flex-row align-items-center'>
                                                            <div className='table__custom__cell cell__prioridad'>
                                                                {e.prioridad === 1 && <span className='cell__prioridad--baja rounded-pill text-white badge'>baja</span>}
                                                                {e.prioridad === 2 && <span className='cell__prioridad--media rounded-pill text-white badge'>media</span>}
                                                                {e.prioridad === 3 && <span className='cell__prioridad--alta rounded-pill text-white badge'>alta</span>}
                                                            </div>
                                                            <div className='table__custom__cell cell__progreso'>
                                                                <ProgressBar className='progreso__bar' now={Math.round(e.progreso)} label={`${Math.round(e.progreso)}%`} max={100}/>
                                                            </div>
                                                            <div className='table__custom__cell cell__responsable d-flex flex-row align-items-center'>
                                                                <img className='me-1' src={Avatar} alt='avatar' />
                                                                {e.nombreUser}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        {/* COMENTARIOS */}
                        <div className='comentarios__container mb-4'>
                            <h4 className='mb-4 text-blue-500'>Comentarios</h4>
                            {loadingComentarios ? (
                                <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                    <Oval
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#0d6efd"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                    <p className='fw-medium'>Loading...</p>
                                </div>
                            ) : (
                                <>
                                    {errorComentarios ? (
                                        <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                            <h3 className='text-danger mb-1'>Mensaje de error:</h3>
                                            <p className='m-0'>{errorComentarios}</p>
                                        </div>
                                    ) : (
                                        <>
                                            {comentarios.length === 0 ? (
                                                <div className='comentarios--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                                                    <h3 className='text-blue-500 fw-semibold mb-1 text-center'>¡No hay comentarios aún!</h3>
                                                    <p className='mb-0 text-center'>Escribe tu primer comentario.</p>
                                                </div>
                                            ) : (
                                                <div>
                                                    {comentarios.map((e, i) => {
                                                        return <div className='comentarios d-flex flex-row' key={i}>
                                                            <img className='me-2' src={Avatar} alt='avatar' />
                                                            <div className='comentarios__textos'>
                                                                <p className='text-md fw-semibold m-0'>{e.Responsable.nombre}</p>
                                                                <p className='text-md mb-2'>{e.texto}</p>
                                                                <p className='text-xs m-0'>{e.fecha_publicacion.replace(/-/g, '/').split("/").reverse().join("/")}</p>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div className='addComentario'>
                            <form onSubmit={handleNewComment}>
                                <h4 className='mb-3 text-blue-500'>Agregar un comentario</h4>
                                <div className='col-12 col-md-9 d-flex flex-column align-items-start'>
                                    <textarea 
                                        id="comentario" 
                                        name="comentario"
                                        placeholder="Escribe un comentario..." 
                                        rows="5"
                                        className="form-control form-control-sm mb-3"
                                        value={comentarioNuevo}
                                        onChange={(e)=>setComentarioNuevo(e.target.value)}
                                    />
                                    {msjErrorComentario !== null && <span className='comentarios--error text-danger'><i className="bi bi-exclamation-circle me-1"></i>{msjErrorComentario}</span>}
                                    <button type='submit' className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-end'>Agregar comentario</button>
                                </div>
                            </form>
                        </div>
                        </section>
                        <aside className='okrDetalle__main__extras'>
                        {loading ? (
                            <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#0d6efd"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <p className='fw-medium'>Loading...</p>
                            </div>
                        ) : (
                            <>
                            {error ? (
                                <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                <h3 className='text-danger mb-1'>Mensaje de error:</h3>
                                <p className='m-0'>{error}</p>
                                </div>
                            ) : (
                                <>
                                {okrSelected && <>
                                    {/* Responsable */}
                                    <p className='mb-1 fw-semibold'>Responsable</p>
                                    <div className='d-flex flex-row align-items-center mb-3'>
                                        <img className='me-1 avatar__aside' src={Avatar} alt='avatar' />
                                        <p className='m-0'>{okrSelected.nombreUser}</p>
                                    </div>
                                    {/* Fecha de inicio */}
                                    <p className='m-0 fw-semibold'>Fecha de inicio</p>
                                    <p className='mb-3'>{okrSelected.fecha_de_inicio.replace(/-/g, '/').split("/").reverse().join("/")}</p>
                                    {/* Proyección */}
                                    <p className='m-0 fw-semibold'>Proyección</p>
                                    <p className='mb-3'>
                                        {okrSelected.proyeccion === 1 && 'Quincenal'}
                                        {okrSelected.proyeccion === 2 && 'Mensual'}
                                        {okrSelected.proyeccion === 3 && 'Bimestral'}
                                        {okrSelected.proyeccion === 4 && 'Trimestral'}
                                        {okrSelected.proyeccion === 5 && 'Semestral'}
                                        {okrSelected.proyeccion === 6 && 'Anual'}
                                    </p>
                                    {/* Prioridad */}
                                    <p className='m-0 fw-semibold'>Prioridad</p>
                                    <div>
                                        {okrSelected.prioridad === 1 && <span className='badge--baja rounded-pill text-white badge'>baja</span>}
                                        {okrSelected.prioridad === 2 && <span className='badge--media rounded-pill text-white badge'>media</span>}
                                        {okrSelected.prioridad === 3 && <span className='badge--alta rounded-pill text-white badge'>alta</span>}
                                    </div>
                                </>}
                                </>
                            )}
                            </>
                        )}
                        </aside>
                    </div>
                </div>
            </detallePersContext.Provider>
        </>
    )
}

export default DetalleOkrPersonal