import React, { useState, useEffect } from 'react'
import "./Perfil.scss"
import { jwtDecode } from "jwt-decode"
import Avatar from "../../assets/img/avatar-3.jpg"

function Perfil() {
    const token = localStorage.getItem("token")
    const obj = jwtDecode(token)
    const user = obj.apirest.objeto

    const [areas, setAreas] = useState([]);
    const [error, setError] = useState(null)

    const fetchAreas = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: user
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setError(data.errorDetalle)
            } else {
                setAreas(data.objeto)
            }
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        fetchAreas()
    }, [])

    return (
        <div className='profile d-flex flex-column align-items-center'>
            <div className='d-flex flex-column align-items-center mb-4'>
                <img className='rounded-circle mb-3' src={Avatar} alt='Avatar' />
                <h3 className='mb-2'>{user.nombre}</h3>
                <p className='m-0'>{user.mail}</p>
                {areas.map((e, i) => {
                    return <p key={i}>{e.id_area === user.area ? e.nombre_del_Area : ""}</p>
                })}
                {error && <p>{error}</p>}
            </div>    
        </div>
    )
}

export default Perfil