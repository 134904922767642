import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import "./HomeDatIN.scss"
import "../../components/FormDatIN/NuevaEstructura/ListaConsultorasDatIN.scss"
import Auditoria from '../../assets/img/planes-accion.png'
import { jwtDecode } from "jwt-decode"

import { usePlanes } from '../../context/PlanesContext'

const ciclos = [
  {
      id: 1,
      año: 2025
  }
]

function IndicadoresByYear() {
  const { empresa, fetchEmpresa, area, fetchArea } = usePlanes()

  const { idConsult, id, idArea } = useParams()

  const auth = localStorage.getItem("token")
  const jwtParse = jwtDecode(auth)
  const USER = jwtParse.apirest.objeto

  const [loading, setLoading] = useState(true)

  useEffect(()=> {
    if(ciclos){
      setLoading(false)
    }

    if (USER.puesto < 3) {
      fetchEmpresa(USER, idConsult, id)
    }
    
    fetchArea(USER, id, idArea)
  },[])

  const ruta = USER.puesto >= 3 ? "" : `/consultora/${idConsult}/empresa/${id}`

  return (
    <>
      <div className='home__DatIN section'>
          <div className='section__header d-flex flex-row align-items-end mb-4'>
            <i className='bi bi-bar-chart-fill me-2'></i>
            <h4 className='m-0'><Link className='breadcrumb__generico' to={'/dat-in'}>DatIN</Link> {USER.puesto < 3 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/dat-in${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> {area}</h4>
          </div>
          {loading ? (
              <div className='loading__datin d-flex flex-column align-items-center justify-content-center'>
                  <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#0d6efd"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
                  <p className='fw-medium'>Loading...</p>
              </div>
          ) : (
              <div className='home__DatIN__main'>
                  <div className='home__DatIN__main__info rounded-3'>
                      <div className='home__DatIN__main__info__textos mb-3 mb-xl-0'>
                          <h2 className='text-white'>Tus indicadores están aquí</h2>
                          <p className='text-white m-0'>Aquí podrás crear indicadores y agregar métricas por año.</p>
                      </div>
                      <img className='' src={Auditoria} alt="" />
                  </div>
                  <div className='home__DatIN__main__years'>
                      {ciclos.map((e,i) => {
                          return <Link 
                              className="card__year d-flex flex-row align-items-center btn shadow-sm rounded-3 border border-light-subtle" 
                              to={`/dat-in${ruta}/area/${idArea}/${e.año}`} 
                              key={i}
                          >
                              <h4 className='p-0 m-0 text-start'>Indicadores {e.año}</h4>
                          </Link>
                      })}
                  </div>
              </div>
          )}
      </div>
    </>
  )
}

export default IndicadoresByYear