import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { Oval } from 'react-loader-spinner'

function ListaAreasDatIN(props) {
    const [loading, setLoading] = useState(true)
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        const fetchAreas = async () => {
            try {
              const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  user: props.user,
                })
              })
              const data = await res.json()
              if(data.status !== 0){
                setLoading(false)
              } else {
                setAreas(data.objeto)
                setLoading(false)
              }
            } catch (error) {
              console.log(error)
            }
        }
  
        fetchAreas()
    }, [])

    return (
        <>
            {loading ? (
                <div className='loading__areas d-flex flex-column align-items-center justify-content-center'>
                <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color="#0d6efd"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <p className='fw-medium'>Loading...</p>
                </div>
            ) : (
                <div className='areas__list mb-4'>
                    {areas.map((e,i) => {
                        return <Link to={`/dat-in/area/${e.id_area}`} className="btn areas__list__area border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center" key={i}>
                            <h4 className='p-0 m-0 text-start'>{e.nombre_del_Area}</h4>
                        </Link>
                    })}
                </div>
            )}
        </>
    )
}

export default ListaAreasDatIN