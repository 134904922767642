import React, { createContext, useState, useContext } from 'react'

const PlanesContext = createContext()

export function PlanesProvider({children}) {
    const [empresa, setEmpresa] =  useState(null)
    const [area, setArea] = useState(null)
    // const [year, setYear] = useState(null)

    const fetchEmpresa = async (user, idConsultora, idEmpresa) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user,
                idConsultora: idConsultora
            })
          })
          const data = await res.json()
          const arr = data.objeto
          const obj = arr.find(e => e.id_empresa === parseInt(idEmpresa))
          setEmpresa(obj.nombre)
        } catch (error) {
          console.log(error)
        }
      }

    const fetchArea = async (user, idEmpresa, idArea) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  user: user,
                  idEmpresa: idEmpresa
                })
              })
              const data = await res.json()
              const arr = data.objeto
              const obj = arr.find(e => e.id_area === parseInt(idArea))
              setArea(obj.nombre_del_Area)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <PlanesContext.Provider value={{ empresa, fetchEmpresa, area, fetchArea }}>
            {children}
        </PlanesContext.Provider>
    )
}

export function usePlanes() {
    return useContext(PlanesContext)
}