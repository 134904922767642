import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import { OkrContext } from '../../context/OkrContext'
import { keyResultContext } from '../../pages/Okr/DetalleKeyResult'

function ModalEditActividad(props) {
    const { idKeyResult } = useParams()

    const { fetchActividades } = useContext(OkrContext)
    const { idActividad, objActividad, setObjActividad, setLoadingIngresos, setArrIngresos, USER } = useContext(keyResultContext)

    const [formData, setFormData] = useState({
        dato: "",
        fecha: "",
        detalle: ""
    })

    const [errors, setErrors] = useState({})
    const [modalErr, setModalErr] = useState(null)

    useEffect(() => {
        if(objActividad){
            const obj = JSON.parse(objActividad)
            console.log(obj)
            setFormData({
                dato: obj.dato !== 0 ? obj.dato : 0,
                fecha: obj.fecha_Actividad,
                detalle: obj.detalle ? obj.detalle : null
            })
        }
    }, [objActividad])

    const validateForm = (data) => {
        const errors = {}
        if(!data.fecha.trim()){
            errors.fecha = "Escoja una fecha."
        }
        if(data.dato === 0){
            if(!data.detalle.trim()) {
                errors.detalle = "Escriba la actividad."
            }
        } else {
            if(!data.dato.trim()) {
                errors.dato = "Escriba la actividad."
            }
        }
        return errors;
    }

    const handleClose = () => {
        setErrors({})
        setFormData({
            dato: "",
            fecha: "",
            detalle: ""
        })
        props.onHide()
        setModalErr(null)
        setObjActividad(null)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleEditActividad = async (e) => {
        e.preventDefault()
        const newErrors = validateForm(formData)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            if(formData.dato === 0){
                try {
                    const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/modActividades`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            user: USER,
                            idActividad: idActividad,
                            detalles: formData.detalle,
                            fecha: formData.fecha
                        })
                    })
                    const data = await res.json()
                    if (data.status !== 0){
                        setModalErr(data.codeError)
                    } else {
                        setLoadingIngresos(true)
                        setModalErr(null)
                        setObjActividad(null)
                        props.onHide()
                        fetchActividades(idKeyResult).then(res => setArrIngresos(res.objeto))
                        setLoadingIngresos(false)
                    }
                } catch (error) {
                    setModalErr(error)
                }
            } else {
                try {
                    const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/modActividades`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            user: USER,
                            idActividad: idActividad,
                            dato: formData.dato,
                            fecha: formData.fecha
                        })
                    })
                    const data = await res.json()
                    if (data.status !== 0){
                        setModalErr(data.codeError)
                    } else {
                        setLoadingIngresos(true)
                        setModalErr(null)
                        setObjActividad(null)
                        props.onHide()
                        fetchActividades(idKeyResult).then(res => setArrIngresos(res.objeto))
                        setLoadingIngresos(false)
                    }
                } catch (error) {
                    setModalErr(error)
                }
            }
            
        } else {
            setModalErr("Completar los campos mencionados.")
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='modal__okr'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='d-flex flex-row'>
                    <h3 className='m-0'>Editar Actividad</h3>
                    <button className='btn' onClick={handleClose}><i className="bi bi-x-lg fw-bold"></i></button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='form__okr d-flex flex-column' onSubmit={handleEditActividad}>
                    {/* Título y fecha */}
                    <div className='row mb-4'>
                        <>
                            {formData.dato === 0 ? (
                                <div className='col-12 col-md-7'>
                                    <label className='mb-1'>Actividad</label>
                                    <input
                                        onChange={handleChange}
                                        type="text" 
                                        id="detalle" 
                                        name="detalle" 
                                        autoFocus
                                        className="form-control form-control-sm col-12"
                                        value={formData.detalle}
                                    />
                                    {errors.detalle && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.detalle}</span>}
                                </div>
                            ) : (
                                <div className='col-12 col-md-7'>
                                    <label className='mb-1'>Actividad</label>
                                    <input
                                        onChange={handleChange}
                                        type="text" 
                                        id="dato" 
                                        name="dato" 
                                        autoFocus
                                        className="form-control form-control-sm col-12"
                                        value={formData.dato}
                                    />
                                    {errors.dato && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.dato}</span>}
                                </div>
                            )}
                        </>
                        <div className='col-12 col-md-5'>
                            <label className='mb-1'>Fecha</label>
                            <input
                                onChange={handleChange}
                                type="date" 
                                id="fecha" 
                                name="fecha" 
                                className="form-control form-control-sm"
                                value={formData.fecha}
                            />
                            {errors.fecha && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fecha}</span>}
                        </div>
                    </div>
                    {modalErr !== null && <span className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{modalErr}</span>}
                    <button type="submit" className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-center'>
                        Modificar Actividad
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEditActividad