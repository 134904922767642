import React from "react"
import MyRoutes from "./router/routes"
import "./App.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  return (
    <>
      <MyRoutes />
    </>
  );
}

export default App;
